import React, { useEffect, useState } from "react";
// import DefaultLayout from "../../layout/defaultlayout";
import Table from "../../components/table";
import "./style.css";
import "./style.css";

import Modal from "../../components/modal";
import axios from "axios";
import Layout from "../../Layouts/Layout";
import ContentNav from "../../components/datanavcontent";
// import { getMessages } from "./functions/getAll";
import { base_url } from "../../components/constants";
import TableLayout from "../../components/table";

function Message_History() {
  const local_alskafi=localStorage.getItem('alskafi_user')
  const token=local_alskafi&&JSON.parse(local_alskafi)
  const [data, setData] = useState([]);
  const [searchHeaderKet, setSearchHeaderKey] = useState("");

  const [pageLoading,setPageLoading]=useState();
  const [originalData,setOriginalData]=useState([])
  const [showMessage, setShowMessage] = useState(false);
  const getMessages=()=>{
    setPageLoading(true)
    axios.get(base_url+'/messages/get_messages?'+`token=${token}`)
    .then((res)=>{
      console.log(res.data)
      if(Array.isArray(res.data.result)){
        console.log(res.data.result)
        setData(res.data.result)
      }
    })
    .catch(e=>console.log(e))
    .finally(()=>{
    setPageLoading(false)
    })
  }
  const handleSearch = (txt) => {
    let allData = [...originalData];
    let pushedData = [];
    if (txt.length == 0) {
      setOriginalData(allData);
      setData(originalData);
    } else {
      for (let i = 0; i < allData.length; i++) {
        if (allData[i].message_senderPhone.toLocaleLowerCase().includes(txt)||allData[i].message_senderName.toLocaleLowerCase().includes(txt)) {
          pushedData.push(allData[i]);
        }
      }
      setData(pushedData);
    }
  };
  useEffect(() => {
    getMessages()
  }, []);

  const headers = [
    {
      label: "رقم الرسالة",
      dataIndex: "message_id",
    },
    {
      label: "إسم الراسل",
      dataIndex: "message_senderName",
    },
    {
      label: "رقم هاتف الراسل",
      dataIndex: "message_senderPhone",
    },

    {
      label: "عنوان الراسل",
      dataIndex: "message_senderAddress",
    },
    {
      label: "بريد الراسل",
      dataIndex: "message_senderEmail",
    },
    {
      label: "نص الرساله",
      dataIndex: "message_text",
    },
    // {
    //   label: "أوامر",
    //   type: "actions",
    //   actions: [
    //     {
    //       label: " عرض الرسالة  ",
    //       action: setShowMessage,
    //     },
    //   ],
    // },
  ];
  return (
    <div className="h-container con-h">
      <Layout
        children={
          <div className="childs">
            <div className="search_component">
                  <input
                    onChange={(e) => {
                      handleSearch(e.target.value);
                    }}
                    type="text"
                    placeholder="إبحث هنا...."
                  />
                </div>
                {/* {console.log("datadata",data)} */}
            {/* <ContentNav head={"سجل الرسائل"} /> */}
            <TableLayout
                  searchHeaderKet={searchHeaderKet}
                  headers={headers}
                  data={data}
                />
                {console.log(data,"datadata")}
          </div>
        }
      />
    </div>
  );
}

export default Message_History;
