// import logo from './logo.svg';
import "./App.css";
import { Route, Routes } from "react-router";
import Home from "./pages/Home/Home";
import "react-toastify/dist/ReactToastify.css";
import "rsuite/dist/rsuite.min.css";
import Login from "./pages/login/Login";
import Products from "./pages/Products/Products";
import Gallery from "./pages/Gallery/Gallery";
import Message_History from "./pages/Message_History";
import Messages from "./pages/Messages/Messages";
import Features from "./pages/Features/Features";
import Socials from "./pages/Socials/Socials";
import SiteInfo from "./pages/SiteInfo/SiteInfo";
import Contacts from "./pages/Contacts/Contacts";
import Banners from "./pages/Contacts/Banners/Banners";
import Categories from "./pages/Categories/Categories";
import CategoryProducts from "./pages/CategoryProducts/CategoryProducts";
import ProductImages from "./pages/ProductImages/ProductImages";
import CompanyProf from "./pages/CompanyProf/CompanyProf";

function App() {
  return (
    <div className="app">
      <Routes>
        {!localStorage?.getItem("alskafi_user") ? (
          <Route path="*" element={<Login />} />
        ) : (
          <>
            <Route path="/" element={<Products />} />
            <Route path="/products" element={<Products />} />
            <Route path="/Gallery" element={<Gallery />} />
            <Route path="/Message_History" element={<Messages />} />
            <Route path="/Features" element={<Features />} />
            <Route path="/Socials" element={<Socials />} />
            <Route path="/SiteInfo" element={<SiteInfo />} />
            <Route path="/Contacts" element={<Contacts />} />
            <Route path="/Banners" element={<Banners />} />
            <Route path="/Categories" element={<Categories />} />
            <Route path="/CategoryProducts" element={<CategoryProducts />} />
            <Route path="/ProductImages" element={<ProductImages />} />
            <Route path="/CompanyProf" element={<CompanyProf />} />
            <Route path="*" element={<Products />} />
            {/* SiteInformation */}
          </>
        )}
      </Routes>
    </div>
  );
}

export default App;
