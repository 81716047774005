import React, { useEffect } from "react";
import Layout from "../../Layouts/Layout";
import { useState } from "react";
import Modal from "../../components/modal";
import { toast } from "react-toastify";
import axios from "axios";
import { AiOutlineLoading } from "react-icons/ai";

import { MapContainer, TileLayer, Marker, useMapEvents } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';


import { base_url } from "../../components/constants";


delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon-2x.png',
  iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
  shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
});

const SiteInfo = () => {
  const local_alskafi=localStorage.getItem('alskafi_user')
  const token=local_alskafi&&JSON.parse(local_alskafi)
  const [showAddModal, setShowAddModal] = useState(false);
  const [siteInfo, setSiteInfo] = useState({});

  const [bannerImg, setBannerImg] = useState(null);
  const [bannerImgUrl,setBannerImgUrl]=useState('');
  const [aboutImg,setAboutImg]=useState(null)
  const [aboutImgURL,setAboutImgURL]=useState(null)
  const [pageLoading, setPageLoading] = useState(false);
  const [position, setPosition] = useState(null);
  const [editLoading,setEditLoading]=useState(false)

  const LocationMarker = () => {
    useMapEvents({
      click(e) {
        console.log(e.latlng)
        // setPosition({lat:e.la})
        setPosition(e.latlng);
      },
    });
    console.log(position)
    return position === null ? null : (
      <Marker position={position}></Marker>
    );
  };

  const getProducts = () => {
    setPageLoading(true);
    axios
      .get(`${base_url}/site_infos/get_all_for_admin?token=${token}`)
      .then((res) => {
        if (res.data.status=='success') {
          setSiteInfo(res.data.result);
          setBannerImgUrl(res.data?.result?.banner)
          setAboutImgURL(res.data?.result?.about_img)
          setPosition({lat:res.data.result?.lat,lng:res.data.result?.longitude})
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setPageLoading(false);
      });
  };

  const handleEdit=()=>{
    setEditLoading(true)
    if(bannerImg!=null){
      const BanFormData=new FormData();
      BanFormData.append('image',bannerImg);
      axios.post(base_url+"/file_upload",BanFormData)
      .then((res1)=>{
        if(res1.data.status=='success'){
          if(aboutImg!=null){
            const aboutFormData=new FormData()
            aboutFormData.append('image',aboutImg)
            axios.post(base_url+"/file_upload",aboutFormData)
            .then((res2)=>{
              if(res2.data.status=='success'){
                const data_send={
                  ...siteInfo,
                  banner:res1.data.result?.image,
                  about_img:res2.data.result?.image,
                  lat:position?.lat,
                  longitude:position?.lng
                }
                console.log(data_send)
                axios.post(base_url+`/site_infos/update_one/${siteInfo?.id}?token=${token}`,data_send)
                .then((res3)=>{
                  if(res3.data.status=='success'){
                    toast.success(res3.data.message)
                    getProducts()
                    setAboutImg(null)
                    setBannerImg(null)
                  }
                  else if(res3.data.status=='error'){
                    toast.error(res3.data.status)
                  }
                  else{
                    toast.error("حدث خطأ ما")
                  }
                })
                .catch(e=>console.log(e))
                .finally(()=>{
                  setEditLoading(false)
                })
              }
            })
            .catch(e=>console.log(e))
          }
          else {
            const data_send={
              ...siteInfo,
              banner:res1.data.result?.image,
              lat:position?.lat,
              longitude:position?.lng
            }
            console.log(data_send)
            axios.post(base_url+`/site_infos/update_one/${siteInfo?.id}?token=${token}`,data_send)
            .then((res3)=>{
              if(res3.data.status=='success'){
                toast.success(res3.data.message)
                getProducts()
                setAboutImg(null)
                setBannerImg(null)
              }
              else if(res3.data.status=='error'){
                toast.error(res3.data.status)
              }
              else{
                toast.error("حدث خطأ ما")
              }
            })
            .catch(e=>console.log(e))
            .finally(()=>{
              setEditLoading(false)
            })
          }
        }

      })
      .catch(e=>console.log(e))
    }
    else {
      if(aboutImg!=null){
        const aboutFormData=new FormData()
        aboutFormData.append('image',aboutImg)
        axios.post(base_url+"/file_upload",aboutFormData)
        .then((res2)=>{
          if(res2.data.status=='success'){
            const data_send={
              ...siteInfo,
              about_img:res2.data.result?.image,
              lat:position?.lat,
              longitude:position?.lng
            }
            console.log(data_send)
            axios.post(base_url+`/site_infos/update_one/${siteInfo?.id}?token=${token}`,data_send)
            .then((res3)=>{
              if(res3.data.status=='success'){
                toast.success(res3.data.message)
                getProducts()
                setAboutImg(null)
                setBannerImg(null)
              }
              else if(res3.data.status=='error'){
                toast.error(res3.data.status)
              }
              else{
                toast.error("حدث خطأ ما")
              }
            })
            .catch(e=>console.log(e))
            .finally(()=>{
              setEditLoading(false)
            })
          }
        })
        .catch(e=>console.log(e))
      }
      else {
        console.log("erer")
        const data_send={
          ...siteInfo,
          lat:position?.lat,
          longitude:position?.lng
        }
        console.log(data_send)
        axios.post(base_url+`/site_infos/update_one/${siteInfo?.id}?token=${token}`,data_send)
        .then((res3)=>{
          if(res3.data.status=='success'){
            toast.success(res3.data.message)
            getProducts()
            setAboutImg(null)
            setBannerImg(null)
          }
          else if(res3.data.status=='error'){
            toast.error(res3.data.status)
          }
          else{
            toast.error("حدث خطأ ما")
          }
        })
        .catch(e=>console.log(e))
        .finally(()=>{
          setEditLoading(false)
        })
      }
    }
  }

  useEffect(()=>{
    getProducts()
  },[])

  return (
    <Layout
      children={
        <div>
          <div className="row">
            <div className=" form-control">
              <label htmlFor="">عنوان عن الشركه بالعربيه</label>
              <input onChange={(e)=>{
                setSiteInfo({...siteInfo,about_title_ar:e.target.value})
              }} type="text" value={siteInfo?.about_title_ar} />
            </div>
            <div className="form-control">
              <label htmlFor="">عنوان عن الشركه بالإنجليزيه</label>
              <input onChange={(e)=>{
                setSiteInfo({...siteInfo,about_title_en:e.target.value})
              }} type="text" value={siteInfo?.about_title_en} />
            </div>
          </div>

          <div className="row">
            <div className=" form-control">
              <label htmlFor="">عنوان البانر بالعربيه</label>
              <input onChange={(e)=>{
                setSiteInfo({...siteInfo,banner_title_ar:e.target.value})
              }} type="text" value={siteInfo?.banner_title_ar} />
            </div>
            <div className="form-control">
              <label htmlFor="">عنوان البانر بالإنجليزيه</label>
              <input onChange={(e)=>{
                setSiteInfo({...siteInfo,banner_title_en:e.target.value})
              }} type="text" value={siteInfo?.banner_title_en} />
            </div>
          </div>
          <div className="row">
            <div className=" form-control">
              <label htmlFor="">نص البانر بالعربيه</label>
              <input onChange={(e)=>{
                setSiteInfo({...siteInfo,banner_text_ar:e.target.value})
              }} type="text" value={siteInfo?.banner_text_ar} />
            </div>
            <div className="form-control">
              <label htmlFor="">نص البانر بالإنجليزيه</label>
              <input onChange={(e)=>{
                setSiteInfo({...siteInfo,banner_text_en:e.target.value})
              }} type="text" value={siteInfo?.banner_text_en} />
            </div>
          </div>

          <div className="row">
            <div className=" form-control">
              <label htmlFor="">عنوان التواصل بالعربيه</label>
              <input onChange={(e)=>{
                setSiteInfo({...siteInfo,contact_title_ar:e.target.value})
              }} type="text" value={siteInfo?.contact_title_ar} />
            </div>
            <div className="form-control">
              <label htmlFor="">عنوان التواصل بالإنجليزيه</label>
              <input onChange={(e)=>{
                setSiteInfo({...siteInfo,contact_title_en:e.target.value})
              }} type="text" value={siteInfo?.contact_title_en} />
            </div>
          </div>

          <div className="row">
            <div className=" form-control">
              <label htmlFor="">رقم الهاتف الأول</label>
              <input onChange={(e)=>{
                setSiteInfo({...siteInfo,phone:e.target.value})
              }} type="text" value={siteInfo?.phone} />
            </div>
            <div className="form-control">
              <label htmlFor="">رقم الهاتف الثانى</label>
              <input onChange={(e)=>{
                setSiteInfo({...siteInfo,phone2:e.target.value})
              }} type="text" value={siteInfo?.phone2} />
            </div>
            <div className="form-control">
              <label htmlFor="">البريد الإلكترونى</label>
              <input onChange={(e)=>{
                setSiteInfo({...siteInfo,email:e.target.value})
              }} type="text" value={siteInfo?.email} />
            </div>
          </div>

          <div className="row">
            <div className=" form-control">
              <label htmlFor="">نص التواصل بالعربيه</label>
              <input onChange={(e)=>{
                setSiteInfo({...siteInfo,contact_text_ar:e.target.value})
              }} type="text" value={siteInfo?.contact_text_ar} />
            </div>
            <div className="form-control">
              <label htmlFor="">نص التواصل بالإنجليزيه</label>
              <input onChange={(e)=>{
                setSiteInfo({...siteInfo,contact_text_en:e.target.value})
              }} type="text" value={siteInfo?.contact_text_en} />
            </div>
          </div>



          <div className="row">
            <div className="form-control">
              <label htmlFor="">نص عن الشركه بالعربيه</label>
              <textarea  onChange={(e)=>{
                setSiteInfo({...siteInfo,about_text_ar:e.target.value})
              }} name="" value={siteInfo.about_text_ar} id=""></textarea>
            </div>
            <div className="form-control">
              <label htmlFor="">نص عن الشركه بالإنجليزيه</label>
              <textarea onChange={(e)=>{
                setSiteInfo({...siteInfo,about_text_en:e.target.value})
              }}  name="" value={siteInfo.about_text_en} id=""></textarea>
            </div>
          </div>
          <div className="site_images">
            <div className="banner_image">
              <label htmlFor="">صوره البانر</label>
              <input onChange={(e)=>{
                setBannerImg(e.target.files[0])
                setBannerImgUrl(URL.createObjectURL(e.target.files[0]))
              }} type="file"  />
              <img src={bannerImgUrl} alt="" />
            </div>
            <div className="about_image">
              <label htmlFor="">صوره عن الشركه</label>
              <input onChange={(e)=>{
                setAboutImg(e.target.files[0])
                setAboutImgURL(URL.createObjectURL(e.target.files[0]))
              }}  type="file" />
              <img src={aboutImgURL} alt="" />
            </div>
          </div>
          <div className="site_images">
            <label htmlFor="">موقع الشركه</label>
            <div style={{width:'90%',margin:'10px auto'}}>
              <label htmlFor="">حدد المكان من الخريطه</label>
              <MapContainer center={[31.95, 35.91]} zoom={13} style={{ height: '80vh', width: '100%' }}>
                <TileLayer
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                />
                <LocationMarker />
              </MapContainer>
              {position && (
                <div style={{ marginTop: '20px' }}>
                  <p>Latitude: {position.lat}</p>
                  <p>Longitude: {position.lng}</p>
                </div>
              )}
            </div>
          <button disabled={editLoading} onClick={()=>{
            handleEdit()
          }} className="btn btn-primary m-auto">حفظ التغيير</button>
          </div>
        </div>
      }
      page_name={"معلومات الموقع"}
      have_add={false}
      handleShowAdd={() => {
        setShowAddModal(true);
      }}
    />
  );
};

export default SiteInfo;
