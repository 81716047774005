import React, { useEffect } from "react";
import Layout from "../../Layouts/Layout";
import { useState } from "react";
import TableLayout from "../../components/table";
import { delIcon, edit, hidden, show } from "../../SvgsIcons/SvgsImages";
import Modal from "../../components/modal";
import { toast } from "react-toastify";
import axios from "axios";
import { AiOutlineLoading } from "react-icons/ai";
import { Spinner } from "reactstrap";
import { useNavigate } from "react-router";
import { base_url } from "../../components/constants";
const Socials = () => {
  const local_alskafi=localStorage.getItem('alskafi_user')
  const token=local_alskafi&&JSON.parse(local_alskafi)
  const navigate = useNavigate();
  const [showAddModal, setShowAddModal] = useState(false);
  const [products, setProducts] = useState([]);
  const [originalFoodCategories, setOriginalFoodCategories] = useState([]);
  const [searchHeaderKet, setSearchHeaderKey] = useState("");
  const [newCat, setNewCat] = useState({
    link: "",
    image: "",
  });
  const [imgUrl,setImgUrl]=useState('')
  const [imgEdit,setImgEdit]=useState(null)
  const [imgLoading, setImgLoading] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [img, setImg] = useState(null);
  const [pageLoading, setPageLoading] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [rowData, setRowData] = useState({});
  const [showDelModal, setShowDelModal] = useState(false);
  const [delLoading, setDelLoading] = useState(false);
  const [showChangeStatusModal, setShowChangeStatusModal] = useState(false);
  const [changeLoading, setChangeLoading] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const headers = [
    {
      label: "*",
      dataIndex: "id",
      // sort: true,
      // search: true,
    },
    {
      label: "صورة رابط التواصل",
      // sort: true,
      type: "children",
      children: ({ headers, row }) => {
        return (
          <div>
            <img
              onClick={() => {
                window.open(row.link,'_blank')
              }}
              style={{ width: "60px", cursor: "pointer" }}
              src={row.image}
              alt=""
            />
          </div>
        );
      },
      // search: true,
    },
    // {
    //   label: "إسم رابط التواصل",
    //   dataIndex: "food_category_name",
    //   // sort: true,
    //   // search: true,
    // },

    {
      label: "أوامر",
      type: "children",
      children: ({ headers, row }) => {
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "3px",
            }}
          >
            <span
              onClick={() => {
                setShowEditModal(true);
                setRowData(row);
                setImgUrl(row?.image)
              }}
              style={{ color: "green", cursor: "pointer" }}
            >
              {edit}
            </span>
            {/* <span
              onClick={() => {
                setShowDelModal(true);
                setRowData(row);
              }}
              style={{ color: "red", cursor: "pointer" }}
            >
              {delIcon}
            </span> */}
            {row.hidden == "1" ? (
              <span
                onClick={() => {
                  setShowChangeStatusModal(true);
                  setRowData(row);
                }}
                style={{ color: "red", cursor: "pointer" }}
              >
                {hidden}
              </span>
            ) : (
              <span
                onClick={() => {
                  setShowChangeStatusModal(true);
                  setRowData(row);
                }}
                style={{ color: "green", cursor: "pointer" }}
              >
                {show}
              </span>
            )}
          </div>
        );
      },
    },
  ];

  const handleAddFile = () => {
    if (img == "") {
      toast.warn("إختر صوره أولا");
      return;
    }
    setImgLoading(true);
    const formData = new FormData();
    formData.append("image", img);
    axios
      .post("", formData)
      .then((res) => {
        if (res.data != "") {
          setNewCat({ ...newCat, image: res.data });
        } else if (res.data == "") {
          toast.error("لم يتم الرفع");
        } else {
          toast.error("حدث خطأ ما");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setImgLoading(false);
      });
  };

  const handleAddFileAffEdit = () => {
    if (img == "") {
      toast.warn("إختر صوره أولا");
      return;
    }
    setImgLoading(true);
    const formData = new FormData();
    formData.append("image", img);
    axios
      .post("", formData)
      .then((res) => {
        if (res.data != "") {
          setRowData({ ...rowData, image: res.data });
        } else if (res.data == "") {
          toast.error("لم يتم الرفع");
        } else {
          toast.error("حدث خطأ ما");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setImgLoading(false);
      });
  };

  const handleAddCategory = () => {
    if(img==null){
      toast.warn('أدخل صوره')
      return
    }
    setAddLoading(true)
    const formData=new FormData();
    formData.append('image',img);
    axios.post(base_url+"/file_upload",formData)
    .then((res)=>{
      console.log(res.data)
      if(res.data.status=='success'){
        const data_send={
          image:res.data.result.image,
          link:newCat.link
        }
        axios.post(base_url+"/socials/add_new"+`?token=${token}`,data_send)
        .then((res)=>{
            if(res.data.status=='success'){
              setShowAddModal(false)
              getProducts()
              toast.success(res.data.message)
            }
            else if(res.data.status=='error'){
              toast.error(res.data.message)
            }
            else{
              toast.error("حدث خطأ ما")
            }
        })
        .catch(e=>console.log(e))
        .finally(()=>{
          setAddLoading(false);
          setEditLoading(false);
          setChangeLoading(false);
          setAddLoading(false);
        })
      }
    })
    .catch(e=>console.log(e))
  };
  const getProducts = () => {
    setPageLoading(true);
    axios
      .get(`${base_url}/socials/get_all_for_admin?token=${token}`)
      .then((res) => {
        if (Array.isArray(res.data.result)) {
          setProducts(res.data.result);
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setPageLoading(false);
      });
  };

  const handleDelCategory = () => {
    setDelLoading(true);
    const data_send = {
      ...rowData,
    };
    axios
      .post("", JSON.stringify(data_send))
      .then((res) => {
        if (res.data.status == "success") {
          toast.success(res.data.message);
          setShowDelModal(false);
        } else if (res.data.status == "error") {
          toast.error(res.data.message);
        } else {
          toast.error("حدث خطأ ما");
        }
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setDelLoading(false);
      });
  };

  const handleChangeStatus = () => {
    setChangeLoading(true);

    axios
      .get(`${base_url}/socials/update_status/${rowData?.id}?token=${token}`)
      .then((res) => {
        if (res.data.status == "success") {
          toast.success(res.data.message);
          setShowChangeStatusModal(false);
          getProducts()
        } else if (res.data.status == "error") {
          toast.error(res.data.message);
        } else {
          toast.error("حدث خطأ ما");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setChangeLoading(false);
        setEditLoading(false);
          setChangeLoading(false);
          setAddLoading(false);
      });
  };

  const handleEditCategory = () => {
    setAddLoading(true)
    setEditLoading(true);
    if(imgEdit==null){
      const data_send={
        link:rowData.link
      }
      axios.post(base_url+"/socials/update_one"+`/${rowData?.id}`+`?token=${token}`,data_send)
      .then((res)=>{
          if(res.data.status=='success'){
            setShowEditModal(false)
            getProducts()
            toast.success(res.data.message)
          }
          else if(res.data.status=='error'){
            toast.error(res.data.message)
          }
          else{
            toast.error("حدث خطأ ما")
          }
      })
      .catch(e=>console.log(e))
      .finally(()=>{
        setEditLoading(false);
        setEditLoading(false);
        setChangeLoading(false);
        setAddLoading(false);
      })
    }
    else {
      const formData=new FormData();
    formData.append('image',imgEdit);
    axios.post(base_url+"/file_upload",formData)
    .then((res)=>{
      console.log(res.data)
      if(res.data.status=='success'){
        const data_send={
          image:res.data.result.image,
          link:rowData.link
        }
        axios.post(base_url+"/socials/update_one"+`/${rowData?.id}`+`?token=${token}`,data_send)
        .then((res)=>{
            if(res.data.status=='success'){
              setShowEditModal(false)
              getProducts()
              toast.success(res.data.message)
            }
            else if(res.data.status=='error'){
              toast.error(res.data.message)
            }
            else{
              toast.error("حدث خطأ ما")
            }
        })
        .catch(e=>console.log(e))
        .finally(()=>{
          setEditLoading(false);
          setEditLoading(false);
          setChangeLoading(false);
          setAddLoading(false);
        })
      }
    })
    .catch(e=>console.log(e))
    }
  };

  const handleSearch = (txt) => {
    let allData = [...originalFoodCategories];
    let pushedData = [];
    if (txt.length == 0) {
      setProducts(allData);
    } else {
      for (let i = 0; i < allData.length; i++) {
        if (allData[i].food_category_name.toLocaleLowerCase().includes(txt)) {
          pushedData.push(allData[i]);
        }
      }
      setProducts(pushedData);
    }
  };

  useEffect(()=>{
    getProducts()
  },[])

  return (
    <Layout
      children={
        <div>
          <div className="home">
            {pageLoading ? (
              <Spinner />
            ) : (
              <>
                {/* <div className="search_component">
                  <input
                    onChange={(e) => {
                      handleSearch(e.target.value);
                    }}
                    type="text"
                    placeholder="إبحث هنا...."
                  />
                </div> */}
                <TableLayout
                  searchHeaderKet={searchHeaderKet}
                  headers={headers}
                  data={products}
                />
              </>
            )}
          </div>

          <Modal
            open={showAddModal}
            toggle={setShowAddModal}
            headerTitle={"إضافه رابط التواصل جديد"}
            children={
              <>
                <h5>
                  {/* {"Are You Sure To Ban User - " + banId?.student_name + " ?"} */}
                </h5>
                <div className="flex-box">
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleAddCategory();
                    }}
                    className="add_form"
                    action=""
                  >
                    {/* <div>
                      <label htmlFor="">إسم رابط التواصل:</label>
                      <input
                        onChange={(e) => {
                          setNewCat({
                            ...newCat,
                            category_name: e.target.value,
                          });
                        }}
                        type="text"
                        placeholder="إسم رابط التواصل"
                      />
                    </div> */}
                    <div>
                      <label htmlFor="">صوره رابط التواصل:</label>
                      <input
                        onChange={(e) => {
                          setImg(e.target.files[0]);
                        }}
                        type="file"
                        name=""
                        id=""
                      />
                    </div>
                    <div>
                      <label htmlFor=""> رابط التواصل:</label>
                      <input
                        onChange={(e) => {
                          setNewCat({...newCat,link:e.target.value})
                        }}
                        type="text"
                        name=""
                        id=""
                      />
                    </div>
                    <button
                      style={{ cursor: addLoading ? "no-drop" : "pointer" }}
                      disabled={addLoading}
                      className="add_btn"
                    >
                      {addLoading ? <AiOutlineLoading /> : "إضافه"}
                    </button>
                  </form>
                </div>
              </>
            }
          />

          <Modal
            open={showEditModal}
            toggle={setShowEditModal}
            headerTitle={"تعديل رابط التواصل "}
            children={
              <>
                <h5>
                  {/* {"Are You Sure To Ban User - " + banId?.student_name + " ?"} */}
                </h5>
                <div className="flex-box">
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleEditCategory();
                    }}
                    className="add_form"
                    action=""
                  >
                    <div>
                      <label htmlFor="">صوره رابط التواصل:</label>
                      <input
                        onChange={(e) => {
                          setImg(e.target.files[0]);
                          setImgEdit(e.target.files[0])
                          setImgUrl(URL.createObjectURL(e.target.files[0]))
                        }}
                        type="file"
                        name=""
                        id=""
                      />
                    </div>
                    <div className="my-2 w-100 text-end">
                      <img style={{width:'50px'}} src={imgUrl} alt="" />
                    </div>
                    <div>
                      <label htmlFor=""> رابط التواصل:</label>
                      <input
                        value={rowData.link}
                        onChange={(e) => {
                          setRowData({...rowData,link:e.target.value})
                        }}
                        type="text"
                        name=""
                        id=""
                      />
                    </div>
                    <button
                      style={{ cursor: editLoading ? "no-drop" : "pointer" }}
                      disabled={addLoading}
                      className="add_btn"
                    >
                      {editLoading ? <AiOutlineLoading /> : "تعديل"}
                    </button>
                  </form>
                </div>
              </>
            }
          />

          <Modal
            open={showDelModal}
            toggle={setShowDelModal}
            headerTitle={`مسح فئه`}
            children={
              <>
                <h5>{`هل تريد مسح رابط التواصل  `}</h5>
                <div className="flex-box del_btns">
                  <button
                    disabled={delLoading}
                    style={{ cursor: delLoading ? "no-drop" : "pointer" }}
                    className="btn btn-danger"
                    onClick={() => {
                      handleDelCategory();
                    }}
                  >
                    {delLoading ? <AiOutlineLoading /> : "نعم"}
                  </button>

                  <button
                    className="btn btn-primary"
                    width={"fit-content"}
                    onClick={() => {
                      setShowDelModal(false);
                    }}
                  >
                    لا
                  </button>
                </div>
              </>
            }
          />

          <Modal
            open={showChangeStatusModal}
            toggle={setShowChangeStatusModal}
            headerTitle={`تغيير الحاله`}
            children={
              <>
                <h5>
                  هل تريد {rowData.hidden == "1" ? "إظهار" : "إخفاء"} هذه رابط التواصل

                </h5>
                <div className="flex-box del_btns">
                  <button
                    disabled={changeLoading}
                    style={{ cursor: changeLoading ? "no-drop" : "pointer" }}
                    className="btn btn-danger"
                    onClick={() => {
                      handleChangeStatus();
                    }}
                  >
                    {changeLoading ? <AiOutlineLoading /> : "نعم"}
                  </button>

                  <button
                    className="btn btn-primary"
                    width={"fit-content"}
                    onClick={() => {
                      setShowChangeStatusModal(false);
                    }}
                  >
                    لا
                  </button>
                </div>
              </>
            }
          />
        </div>
      }
      page_name={"روابط التواصل الإجتماعى"}
      have_add={true}
      handleShowAdd={() => {
        setShowAddModal(true);
      }}
    />
  );
};

export default Socials;
