import { IoMdNotifications } from "react-icons/io5";
import {
  Blogs,
  Notification,
  advice,
  carts,
  categories,
  emergancy,
  orders,
  reviews,
  info,
  settings,
  upgrade,
  user,
  vaccians,
  market,
  test,
  foods,
  policies,
  paper,
  doctors,
  banner,
  features,
} from "../SvgsIcons/SvgsImages";
// import { info } from "sass";
export const SideNavData = [
  // {
  //   path: "/Gallery",
  //   label: "معرض الصوره",
  //   icon: banner,
  //   // icon:<IoMdNotifications/>
  //   // icon:<aioutlien
  // },
  {
    path: "/products",
    label: "المنتجات",
    icon: features,
    // icon:<IoMdNotifications/>
    // icon:<aioutlien
  },
  {
    path: "/Message_History",
    label: "الرسائل",
    icon: categories,
    // icon:<IoMdNotifications/>
    // icon:<aioutlien
  },
  {
    path: "/Features",
    label: "الخصائص",
    icon: categories,
    // icon:<IoMdNotifications/>
    // icon:<aioutlien
  },
  {
    path: "/Socials",
    label: "روابط التواصل",
    icon: categories,
    // icon:<IoMdNotifications/>
    // icon:<aioutlien
  },
  {
    path: "/SiteInfo",
    label: "معلومات الموقع",
    icon: categories,
    // icon:<IoMdNotifications/>
    // icon:<aioutlien
  },
  {
    path: "/Contacts",
    label: "التواصل",
    icon: categories,
    // icon:<IoMdNotifications/>
    // icon:<aioutlien
  },
  {
    path: "/Banners",
    label: "البانرز",
    icon: categories,
    // icon:<IoMdNotifications/>
    // icon:<aioutlien
  },
  // {
  //   path: "/Categories",
  //   label: "الفئات",
  //   icon: categories,
  //   // icon:<IoMdNotifications/>
  //   // icon:<aioutlien
  // },
  {
    path: "/CompanyProf",
    label: "نبذه عن الشركه",
    icon: categories,
    // icon:<IoMdNotifications/>
    // icon:<aioutlien
  },
  // {
  //   path: "/SiteInformation",
  //   label: "معلومات الموقع",
  //   icon: info,
  //   // icon:<IoMdNotifications/>
  //   // icon:<aioutlien
  // },

];
