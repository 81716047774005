import React, { useState } from "react";
import "./login.css";
import axios from "axios";
import { toast } from "react-toastify";
import "./login.css";
import { AiOutlineLoading } from "react-icons/ai";
import { base_url } from "../../components/constants";
const Login = () => {
  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });
  const [loginLoading, setLoginLoading] = useState(false);



  const loginFunc = () => {
    setLoginLoading(true);
    const data_send = {
      ...loginData,
    };
    axios
      .post(`${base_url}/user/admin_login`, data_send)
      .then((res) => {
        console.log(res.data);
        // return
        if (res.data.status == "error") {
          toast.error(res.data.message);
        } else if (res.data.status == "success") {
          toast.success("تم التسجيل بنجاح");
          localStorage.setItem("alskafi_user",JSON.stringify(res.data.result))
          window.location.href="/products"
          // window.location.reload()

        } else {
          toast.error("حدث خطأ ما");
        }
      })
      .finally(() => {
        setLoginLoading(false);
      })
      .catch((e) => console.log(e));
  };
  return (
    <div className="login">
      <div className="login_form">
        <form onSubmit={(e)=>{
          e.preventDefault();
          loginFunc()
        }} action="">
          <img
            src={
              require("../../assets/images/logo.png")
            }
            alt=""
          />
          <input
            onChange={(e) => {
              setLoginData({ ...loginData, email: e.target.value });
            }}
            type="text"
            placeholder="أدخل البريد الإلكترونى"
          />
          <input
            onChange={(e) => {
              setLoginData({ ...loginData, password: e.target.value });
            }}
            type="text"
            placeholder="أدخل كلمة السر"
          />
          <button
            disabled={loginLoading}
            style={{ cursor: loginLoading ? "no-drop" : "pointer" }}
          >
            {loginLoading ? <AiOutlineLoading /> : "تسجيل"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
